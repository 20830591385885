import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import _ from 'lodash';
// import PhoneInput from 'react-phone-number-input';
import { isValidPhoneNumber } from 'react-phone-number-input';
import PhoneInput from 'react-phone-input-2';
import ReCAPTCHA from "react-google-recaptcha";
import 'react-phone-input-2/lib/style.css';
import parsePhoneNumber from 'libphonenumber-js';

interface LeadState {
  email: string;
  phone: string;
  isOtpSent: boolean;
  otp: string;
  exampleSent?: boolean;
  isPhoneSent: boolean;
  recaptchaValue: string|null;
}

class Example extends React.Component<any, LeadState> {
  constructor(props: LeadState) {
    super(props);
    this.state = {
      email: '',
      phone: '',
      otp: '',
      isOtpSent: false,
      isPhoneSent: false,
      recaptchaValue: '',
    };

    this.handleEmail = this.handleEmail.bind(this);
    this.handlePhone = this.handlePhone.bind(this);
    this.handleSubmitPhone = this.handleSubmitPhone.bind(this);
    this.handleOtp = this.handleOtp.bind(this);
    this.handleSubmitOtp = this.handleSubmitOtp.bind(this);
    this.handleRecaptcha = this.handleRecaptcha.bind(this);
    this.parsePhone = this.parsePhone.bind(this);
  }

  handleRecaptcha(value: string|null) {
    this.setState({recaptchaValue: value});
  }

  handleEmail(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({email: event.target.value});
  }

  handlePhone(phone: string) {
    this.setState({phone});
  }

  handleOtp(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({otp: event.target.value});
  }
  
  validateEmail(email: string) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  validateEmailOrEmpty(email: string) {
    return !email || this.validateEmail(email);
  }

  isValidPhoneNumberOrEmpty(phone: string) {
    return !phone || isValidPhoneNumber(phone);
  }

  parsePhone() {
    const parsedNumber = parsePhoneNumber(this.state.phone, 'IL');
    const actualNumber = parsedNumber?.number.toString();
    const res = actualNumber?.substr(1);
    console.log(`parsed number ${res}`);
    return res;
  }

  handleSubmitPhone(event: any) {
    event.preventDefault();
    const phone = this.parsePhone();
    if (phone && this.validateEmail(this.state.email)) {
      console.log(JSON.stringify(this.state));
      const date = new Date();
      date.setDate(date.getDate() + 1);
      date.setHours(12);
      fetch('/otp', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({...this.state, phone, date: date.toISOString()})
      });
      this.setState({isPhoneSent: true});
    }
    else {
      console.log('bad number');
    }
  }
  async handleSubmitOtp(event: any) {
    event.preventDefault();
    console.log(JSON.stringify(this.state));
    this.setState({isOtpSent: true})
    const response = await fetch('/example', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({...this.state, phone: this.parsePhone()})
    });
    this.setState({exampleSent: response.ok});
  }
  render() {
    if (!_.isNil(this.state.exampleSent) && !this.state.exampleSent) {
      return (
        <div className='main-block'>
          אירעה שגיאה
        </div>
      );
    }
    else if (this.state.isOtpSent) {
      return (
        <div className='main-block'>
          נא המתן לטלפון מטלי
        </div>
      );
    }
    else if (this.state.isPhoneSent){
      return (
        <div className='main-block'>
          <form 
            onSubmit={this.handleSubmitOtp}>
            <input type='text' value={this.state.otp} onChange={this.handleOtp} id='otp' placeholder='קוד אימות'/>
            <button type='submit'>שלח קוד אימות</button>
          </form>
        </div>
      );
    }
    else {
      return (
        <div className='main-block'>
          <form 
            onSubmit={this.handleSubmitPhone}>
            <input className={this.validateEmailOrEmpty(this.state.email) ? "" : "error"} type='text' value={this.state.email} onChange={this.handleEmail} id='email' placeholder='אימייל'/>
            <PhoneInput 
              inputStyle={{borderRadius: '0px', width: '100%'}}
              country={'il'} 
              value={this.state.phone} 
              onChange={this.handlePhone}  
              onlyCountries={['il']} 
              isValid={(value, country) => {
                return this.isValidPhoneNumberOrEmpty('+' + this.state.phone);
              }}
            />
            <ReCAPTCHA sitekey="6LeTlYsaAAAAAJ6VrTIxF2fe9_6EbSAujKxP-X5F" onChange={this.handleRecaptcha}/>
            <button type='submit'>שלח</button>
          </form>
        </div>
      );
    }
  }
}

ReactDOM.render(
  <Example />,
  document.getElementById('root')
);
